import React from 'react'
import PortableText from '@sanity/block-content-to-react'
import { serializers } from '../helpers/serializers'

interface Props {
  body: React.ReactNode
  className?: string
}

export const BlockContent = ({ body, className }: Props): JSX.Element => {
  return <PortableText className={className} blocks={body} serializers={serializers} />
}
