import React from 'react'
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import clientConfig from '../../clientConfig'
import styled from 'styled-components'

const Container = styled.figure`
  figcaption {
    text-align: center;
    margin-top: 3px;
  }
`

interface Props {
  node: {
    asset: {
      _id: string
    }
    caption?: string
    alt?: string
  }
}

export const Figure = ({ node }: Props): JSX.Element => {
  if (!node || !node.asset || !node.asset._id) {
    return <p>Error Loading Image</p>
  }
  const fluidProps = getFluidGatsbyImage(node.asset._id, { maxWidth: 675 }, clientConfig.sanity)
  return (
    <Container>
      <Img fluid={fluidProps} alt={node.alt} />
      <figcaption>{node.caption}</figcaption>
    </Container>
  )
}
