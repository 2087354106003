import React from 'react'
import { Figure } from '../components/Figure'

interface Props {
  node: React.ReactNode
}

const FallbackRenderer = (props: Props): JSX.Element => {
  console.log(props)
  return <p>Error no Rendering Method</p>
}

export const serializers = {
  types: {
    undefined: FallbackRenderer,
    inlineImage: Figure,
  },
}
